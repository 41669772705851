import React from "react"
import { Videos } from "../../data/assets"
import { Routes } from "../../data/routes"
import Seo from "../../components/seo"

import Card from "../../components/card"
import Img from "../../components/img"
import Video from "../../components/Video"

import { Disclosure } from "@headlessui/react"
import {
  ChevronDownIcon,
  VideoCameraIcon,
  DocumentIcon,
  CalendarIcon,
  PresentationChartBarIcon,
  MailIcon,
} from "@heroicons/react/outline"

const Content = {
  pageName: "Teacher Hub",
  header: `Welcome to the Yup Teacher Hub!`,
  headerImage: "teacher-hub-cover.png",
  description: `We are excited to work with you to
        provide 24/7 math support for your students!`,

  // Videos section
  videoHeader: "New to Yup? Start with these 3 videos",
  videos: [
    {
      header: "Yup for Teachers",
      description: "A one-minute intro to Yup",
      video: Videos.yupForTeachers,
    },
    {
      header: "Yup for Teachers",
      description: `A 12-minute introduction to Yup and
                how to get Yup started with your students`,
      video: Videos.teacherWalkthrough,
    },
    {
      header: "How to make Yup part of your classroom",
      description: `A 4-minute overview to help you bring Yup to your classroom`,
      video: Videos.teacherWalkthrough,
    },
  ],

  // Resources section
  resources: [
    {
      header: "Learn more about Yup",
      icon: "icon-math-tools.svg",
      description: `Here you can learn more about what how Yup works`,
      links: [
        {
          icon: VideoCameraIcon,
          name: "Teacher Walkthrough: Yup Student App",
          description: `A 5-minute walkthrough of the student experience`,
          link: "https://vimeo.com/513570752",
        },
        {
          icon: VideoCameraIcon,
          name: "Teacher Walkthrough: Yup Tutors",
          description: `A 10-minute walkthrough of who are tutors are and
                        how they work with your students`,
          link: "https://vimeo.com/528930034",
        },
        {
          icon: DocumentIcon,
          name: "Yup Security and Compliance",
          description: `View the Federal and State policies used to
                        ensure student safety`,
          link: "https://drive.google.com/file/d/11Rn7zvGRZfiyHVwrf5034spwt_m9exY8/view",
        },
      ],
    },
    {
      header: "How to Use Yup",
      icon: "icon-exchange.svg",
      description: `Here you can learn more about the Yup Dashboard
                and how to use the available data`,
      links: [
        {
          icon: VideoCameraIcon,
          name: "Teacher Walkthrough: Yup Dashboard",
          description: `A 3-minute walkthrough of the Dashboard and available data`,
          link: "https://vimeo.com/527955735",
        },
        {
          icon: DocumentIcon,
          name: "Yup in Action: Annotated Transcripts of a Real Tutoring Session",
          description: `Check out how tutors specifically help students Understand,
                        Plan, Solve, Check, and Reflect in a session`,
          link: "https://drive.google.com/file/d/19cHaJTe_cZIyxnyJ6a5Z9nyjgOkxjuKp/view",
        },
        {
          icon: CalendarIcon,
          name: "Yup On-Demand",
          description: `Looking to schedule an in-class review and have students access
                        Yup when they are stuck? Just fill out this quick form at least 24 hrs
                        in advance to use Yup on-demand with your students!`,
          link: "https://yuptechnologies.typeform.com/to/a297nerP",
        },
      ],
    },
    {
      header: "Getting Students Started with Yup",
      icon: "icon-socratic-method.svg",
      description: `Here you will find resources to help you introduce Yup to your students`,
      links: [
        {
          icon: VideoCameraIcon,
          name: "Student Onboarding Video",
          description: `Use this 3-minute video to introduce Yup to your students`,
          link: "https://vimeo.com/515346713",
        },
        {
          icon: PresentationChartBarIcon,
          name: "Student Onboarding Slides [Middle/High School]",
          description: `Prefer to introduce Yup to students yourself? Use this
                        slide deck - everything you need to say is in the notes`,
          link: "https://docs.google.com/presentation/d/1LYvs5oErn7tiqAR_McQn3-RVMdFNZ2BFL27WlHvbidA/edit",
        },
        {
          icon: PresentationChartBarIcon,
          name: "Student Onboarding Slides [Elementary School]",
          description: `Prefer to introduce Yup to students yourself? Use this
                        slide deck - everything you need to say is in the notes`,
          link: "https://docs.google.com/presentation/d/16yA70aAImQpbYTNrUa6Md15_3iXYphW0vtIj6AymH4Q/edit",
        },
        {
          icon: DocumentIcon,
          name: "Sentence Starters for Students",
          description: `This chart includes sentence starters that your students can use with Yup
                        tutors depending on where they are stuck! `,
          link: "https://drive.google.com/file/d/1fNmtMnc_iT1LbBSmdeykwhlx0WTZ_Y4i/view",
        },
      ],
    },
    {
      header: "Keeping Students Engaged",
      icon: "icon-enrichment.svg",
      description: `Here you will find resources to help you encourage and support
                students continued use of Yup`,
      links: [
        {
          icon: VideoCameraIcon,
          name: "Yup Refresher videos",
          description: `A collection of short, animated refresher videos inspired by students`,
          link: "https://sites.google.com/yup.com/yup-teacher-hub/student-resources",
        },
        {
          icon: PresentationChartBarIcon,
          name: "Mini Lessons to Engage Students",
          description: `A collection of 10-minute lessons to help address common
                        student questions when using Yup`,
          link: "https://docs.google.com/document/d/1Co3za4xMPKfnV_wIAS0O8AJa5wt8Um2_cXjYlcboCJs/edit",
        },
        {
          icon: DocumentIcon,
          name: "Student Directed Experiences/Resources",
          description: `A collection of self-guided, interactive, student lessons
                        to help them get the most from their Yup experience`,
          link: "https://docs.google.com/document/d/1YpIt07koaDva2XFC-p1Obmdba_ic1r-8ixLI9FY01hY/edit",
        },
        {
          icon: MailIcon,
          name: "Weekly Yup Tips",
          description: `To help encourage and remind students to use Yup`,
          link: "https://docs.google.com/document/d/1zyAQzIBABGkjN_bg66GiByZw8VqRmRI2WH5aFjA7vrw/edit",
        },
      ],
    },
    {
      header: "Supporting Families",
      icon: "icon-families.svg",
      description: `Here you will find resources to help introduce Yup to families
                and support the use of Yup at home`,
      links: [
        {
          icon: VideoCameraIcon,
          name: "Yup for Families",
          description: `A one-minute animated intro to Yup`,
          link: "https://vimeo.com/511218795",
        },
        {
          icon: VideoCameraIcon,
          name: "Yup Overview for Families video [English]",
          description: `A 5-minute introduction to Yup and how to support Yup at home`,
          link: "https://vimeo.com/479110080",
        },
        {
          icon: VideoCameraIcon,
          name: "Yup Overview for Families video [Spanish]",
          description: `A 5-minute introduction to Yup and how to support Yup at home`,
          link: "https://vimeo.com/480020070",
        },
        {
          icon: DocumentIcon,
          name: "Parent Email Blast with Pre-Recorded Videos",
          description: `Sample email text to send to families to inform them of the
                        school's partnership with Yup and how to get students started`,
          link: "https://docs.google.com/document/d/16aNU2EPKkkOBgVpNHiYxnPPg-khFCuNV3BwvGSeMliA/edit",
        },
        {
          icon: PresentationChartBarIcon,
          name: "Yup Overview Slide for Parents",
          description: `To include in Open House or Parent/Guardian Meeting slide decks`,
          link: "https://docs.google.com/presentation/d/1Wi9KQ5CWLNRwOr8XccbIM_JXJ47DqQ-PCu9nmo78HvA/edit",
        },
        {
          icon: MailIcon,
          name: "Weekly Yup Tips",
          description: `To help remind parents about Yup and encourage the use of Yup at home `,
          link: "https://docs.google.com/document/d/1XXmrR7GjzRIWRvKICYkOi_JP0ZrGHfgkWdsG2vSUuHQ/edit",
        },
        {
          icon: DocumentIcon,
          name: "Yup Family FAQs Flyer",
          description: ``,
          link: "https://drive.google.com/file/d/17s3GdykbmvIZCsgRbFubSYB0lzYzQbcj/view",
        },
        {
          icon: DocumentIcon,
          name: "The Basics of Yup Flyer [English]",
          description: ``,
          link: "https://drive.google.com/file/d/1SjX0ENy0rIR0EOvDt_0DIEtcLQ0PWeiA/view",
        },
        {
          icon: DocumentIcon,
          name: "The Basics of Yup Flyer [Spanish]",
          description: ``,
          link: "https://drive.google.com/file/d/1jJzo57ZTKlEXwrxgqkCOzWNQ37O6w3tE/view",
        },
        {
          icon: DocumentIcon,
          name: "The Basics of Yup Flyer [Chinese]",
          description: ``,
          link: "https://drive.google.com/file/d/1RjtIkNcK4txn9xj3kVn5aZZ091RU8MdU/view",
        },
      ],
    },
  ],

  // Support section
  supportText:
    "For additional support reach out to your dedicated Yup specialist Danielle",
  supportLinkText: "danielle@yup.com",
  supportLink: "mailto:danielle@yup.com",
}

function Header() {
  // Original TailwindUI component: https://tailwindui.com/components/marketing/sections/header#component-2c3b25e7b9e4490edd7b6950692c0a11
  return (
    <div>
      <div className="bg-secondary">
        <Img className="w-full max-h-32 mx-auto" src={Content.headerImage} />
      </div>
      <div className="text-center mx-auto max-w-7xl p-8">
        <h1 className="text-3xl md:text-5xl tracking-tight font-extrabold text-gray-900">
          {Content.header}
        </h1>
        <p className="mt-3 text-gray-500 text-xl md:text-3xl">
          {Content.description}
        </p>
      </div>
    </div>
  )
}

function VideoSection() {
  // Original TailwindUI component: https://tailwindui.com/components/marketing/sections/cta-sections#component-58b6441d042e26470c2d485039ead146
  return (
    <Card>
      <h1 className="text-3xl md:text-4xl tracking-tight font-extrabold text-gray-900 text-center p-4">
        {Content.videoHeader}
      </h1>
      <div className="flex flex-col md:flex-row">
        {Content.videos.map(video => (
          <div
            key={`video-section-${video.header}`}
            className="flex flex-col items-center flex-1 p-4"
          >
            <div className="w-full md:h-32 lg:h-48 xl:h-56">
              <Video url={video.video.url} image={video.video.cover} />
            </div>
            <div className="text-center w-full py-4">
              <p className="mt-2 text-gray-900 text-xl font-extrabold tracking-tight">
                {video.header}
              </p>
              <p className="mt-3 text-gray-500">{video.description}</p>
            </div>
          </div>
        ))}
      </div>
    </Card>
  )
}

function ResourceSection() {
  // Original TailwindUI component: https://tailwindui.com/components/marketing/sections/faq-sections#component-8699d80b13ef524eb573e54b4d4b89d1
  // Original TailwindUI component: https://tailwindui.com/components/application-ui/lists/stacked-lists#component-43253d4065559b08c65a0d2918310810
  return (
    <dl className="mt-6">
      {Content.resources.map(resource => (
        <Disclosure as={Card} key={resource.header}>
          {({ open }) => (
            <>
              <dt className="text-lg">
                <Disclosure.Button className="text-left w-full flex flex-col justify-between items-center text-gray-400 sm:flex-row">
                  <div className="flex flex-col sm:flex-row text-center sm:text-left">
                    <Img src={resource.icon} className="h-24 w-24 mx-auto" />
                    <div className="p-8">
                      <h2 className="font-bold text-xl text-gray-900">
                        {resource.header}
                      </h2>
                      <p className="text-gray-500">{resource.description}</p>
                    </div>
                  </div>
                  <span className="sm:ml-6 h-7 flex items-center">
                    <ChevronDownIcon
                      className={`h-6 w-6 transform ${
                        open ? "-rotate-180" : "rotate-0"
                      }`}
                    />
                  </span>
                </Disclosure.Button>
              </dt>
              <Disclosure.Panel as="dd" className="mt-2 sm:ml-32">
                <ul role="list" className="divide-y divide-gray-100">
                  {resource.links.map(link => (
                    <li key={link.name}>
                      <a
                        className="p-4 flex flex-col text-center sm:text-left sm:flex-row hover:bg-gray-50"
                        target="_blank"
                        href={link.link}
                      >
                        <link.icon className="mx-auto sm:mx-0 mb-4 h-10 w-10 text-gray-300" />
                        <div className="sm:ml-4">
                          <p className="font-medium text-gray-900">
                            {link.name}
                          </p>
                          <p className="text-sm text-gray-500">
                            {link.description}
                          </p>
                        </div>
                      </a>
                    </li>
                  ))}
                </ul>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      ))}
    </dl>
  )
}

function SupportSection() {
  // Original Tailwind UI component: https://tailwindui.com/components/marketing/sections/heroes#component-6364cb4174b8dfdfbd7fa71ac72ab862
  return (
    <div className="relative bg-secondary">
      <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
        <div className="relative px-4 py-4 sm:px-6  lg:px-8">
          <p className="max-w-7xl mx-auto text-center text-white text-lg my-8">
            {Content.supportText}:
            <a
              href={Content.supportLink}
              target="_blank"
              className="text-primary mx-2"
            >
              {Content.supportLinkText}
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}

const TeacherHub = () => {
  return (
    <main className="bg-background h-full">
      <Seo title={Content.pageName} route={Routes.teacherHub} />
      <Header />
      <VideoSection />
      <ResourceSection />
      <SupportSection />
    </main>
  )
}

export default TeacherHub
